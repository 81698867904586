import { Field, Query } from '@tilework/opus';

import {
    SliderQuery as SourceSliderQuery,
} from 'SourceQuery/Slider.query';

/** @namespace AlmusbahblendPwa/Query/Slider/Query */
export class SliderQuery extends SourceSliderQuery {
    // TODO implement logic

    _getSlideFields() {
        return [
            new Field('slide_text'),
            new Field('slide_link'),
            new Field('slide_id'),
            new Field('mobile_image'),
            new Field('desktop_image'),
            new Field('title'),
            new Field('is_active'),
        ];
    }
}

export default new SliderQuery();
