import {
    SliderWidgetComponent as SourceSliderWidgetComponent,
} from 'SourceComponent/SliderWidget/SliderWidget.component';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { ImageRatio } from 'Component/Image/Image.type';
import Slider from 'Component/Slider';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import { getStoreState } from 'Util/Store';
import { AfterPriority } from 'Util/Request/LowPriorityRender';
import './SliderWidget.override.style';

/** @namespace AlmusbahblendPwa/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidgetComponent {
    // TODO implement logic

    renderSlideImage(slide, i) {
        const { onLoad } = this.props;
        const {
            slide_text,
            isPlaceholder,
            title: block,
            slide_link = '',
            slide_id = 0,
        } = slide;
        
        // const { ConfigReducer: { base_link_url = window.location.href, base_url = window.location.href } = {} } = getStoreState();
        // let fullUrl = slide_link.includes(base_link_url);
        return (
            <figure
              block="SliderWidget"
              elem="Figure"
              key={ i }
            >
                <Link
                    to={ slide_link }
                    block="SliderWidget"
                    elem="Link"
                    id={ block.toLowerCase().replace(/ /g,"") }
                >
                    <Image
                    mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                    ratio={ ImageRatio.IMG_CUSTOM }
                    src={ this.getSlideImage(slide) }
                    isPlaceholder={ isPlaceholder }
                    onImageLoad={ onLoad }
                    />
                    <figcaption
                    block="SliderWidget"
                    elem="Figcaption"
                    mix={ { block } }
                    >
                        <Html content={ slide_text || '' } />
                    </figcaption>
                </Link>
            </figure>
        );
    }


    renderSlide(slide, i) {
        const { onLoad } = this.props;
        // const { activeImage } = this.state;
        // const { isPriorityLoaded } = window;

        // if (activeImage !== i && !isPriorityLoaded) {
        //     return (
        //         <AfterPriority fallback={ <div /> }>
        //             { this.renderSlideImage(slide, i) }
        //         </AfterPriority>
        //     );
        // }

        // return this.renderSlideImage(slide, i);
        const {
            slide_text,
            isPlaceholder,
            title: block,
            slide_link = '',
        } = slide;
        // console.log("Slide Data: ", slide);
        return (
            <figure
              block="SliderWidget"
              elem="Figure"
              key={ i }
            >
                <Link
                    to={ slide_link }
                    block="SliderWidget"
                    elem="Link"
                    id={ block.toLowerCase().replace(/ /g,"") }
                >
                    <Image
                    mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                    ratio="custom"
                    src={ this.getSlideImage(slide) }
                    isPlaceholder={ isPlaceholder }
                    onImageLoad={ onLoad }
                    />
                    <figcaption
                    block="SliderWidget"
                    elem="Figcaption"
                    mix={ { block } }
                    >
                        <Html content={ slide_text || '' } />
                    </figcaption>
                </Link>
            </figure>
        );
    }

    render() {
        const { activeImage } = this.state;
        const { slider: { slides, title: block } } = this.props;

        if (!slides) {
            return null;
        }
        
        return (
            <Slider
              mix={ { block: 'SliderWidget', mix: { block } } }
              showCrumbs
              activeImage={ activeImage }
              onActiveImageChange={ this.onActiveImageChange }
            >
                { slides.map(this.renderSlide.bind(this)) }
            </Slider>
        );
    }

}

export default SliderWidgetComponent;
