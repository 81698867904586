import { connect } from 'react-redux';

import SliderQuery from 'Query/Slider.query';
import { NotificationType } from 'Store/Notification/Notification.type';
import {
    mapDispatchToProps,
    mapStateToProps,
    SliderWidgetContainer as SourceSliderWidgetContainer,
} from 'SourceComponent/SliderWidget/SliderWidget.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/SliderWidget/Container */
export class SliderWidgetContainer extends SourceSliderWidgetContainer {
    // TODO implement logic

    state = {
        slider: {
            slide_speed: 0,
            slides: [{
                slide_id: 0,
                slide_text: '',
                mobile_image: '',
                desktop_image: '',
                slider_link: '',
                title: '',
                is_active: true,
                isPlaceholder: true,
            }],
        },
    };


    requestSlider() {
        const { sliderId, showNotification, isOffline } = this.props;
        const {
            actionName: {
                slider: {
                    slider_id: preloadedSliderId,
                } = {},
                slider: preloadedSlider = {},
            },
        } = window;

        if (sliderId === Number(preloadedSliderId)) {
            this.setState({ slider: preloadedSlider });

            return;
        }

        this.fetchData(
            [SliderQuery.getQuery({ sliderId: String(sliderId) })],
            ({ slider }) => this.setState({ slider }),
            (e) => showNotification(NotificationType.ERROR, __('Error fetching Slider!'), e),
            isOffline,
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderWidgetContainer);
